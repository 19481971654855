// Nav.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Nav.css'; // Import CSS file for styling

const Nav = ({ scrollToSection }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav>
      <div className="logo">
        <Link to="/">Skilltojob</Link>
      </div>
      <div className={`menu-toggle ${isOpen ? 'open' : ''}`} onClick={toggleMenu}>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>
      <ul className={`nav-links ${isOpen ? 'open' : ''}`}>
        <li><Link to="#home" onClick={() => scrollToSection('home')}>Home</Link></li>
        <li><Link to="#aboutus" onClick={() => scrollToSection('aboutus')}>About Us</Link></li>
        <li><Link to="#skilled" onClick={() => scrollToSection('skilled')}>Skilled Manpower</Link></li>
        <li><Link to="#core" onClick={() => scrollToSection('core')}>Core Values</Link></li>
        <li><Link to="#services" onClick={() => scrollToSection('services')}>Our Services</Link></li>
        <li><Link to="#jobdetails" onClick={() => scrollToSection('jobdetails')}>Job Details</Link></li>
        <li><Link to="#jobregister" onClick={() => scrollToSection('jobregister')}>Register for Jobs</Link></li>
      </ul>
    </nav>
  );
}

export default Nav;
