import React from "react";
import { Link } from "react-router-dom";
import "./Home.css"; // Import CSS file for styling
import { FaBook, FaShieldAlt, FaHandsHelping, FaGlobe, FaBriefcase } from "react-icons/fa";
import { FaFileSignature, FaUserTie, FaLanguage, FaPassport, FaBriefcaseMedical } from "react-icons/fa";
import { FaCalendarAlt, FaClock, FaMoneyBillAlt, FaMoneyCheckAlt, FaMoneyBillWave, FaStopwatch } from "react-icons/fa";
import { FaBuilding, FaCar, FaCog, FaBolt, FaCode } from "react-icons/fa";
import { animateScroll as scroll } from "react-scroll";
import hero from "./hero.mp4";
import { FaBullseye, FaHandshake } from "react-icons/fa";
import Nav from "./Nav";
import firstbroucher from "../../asset/DNP & LP Staff - Brochure - Final (2) (1).pdf";
import secondbroucher from "../../asset/DNP & LP Staff - Leaflet - A5 - Job Opportunities in Japan - c2c (2).pdf";
import thirdbroucher from "../../asset/japan manpower requirement updated one (1).pdf";
const Home = () => {
  const scrollToSection = (id) => {
    scroll.scrollTo(`#${id}`, {
      duration: 800, // Scroll duration in milliseconds
      smooth: "easeInOutQuart", // Smooth scrolling animation
    });
  };
  return (
    <div className="home" id="home">
      <section
        className="hero"
        style={{ background: `url(${hero})`, backgroundSize: "cover", backgroundPosition: "center" }}
      >
        <video autoPlay loop muted className="hero-video">
          <source src={hero} type="video/mp4" />
        </video>
        <div className="hero-content">
          <h1>Welcome to Skilltojob</h1>
          {/* <p>A Dynamic Collaboration for Engineering Excellence</p> */}
          <p>Find Your Dream Engineering Job in Japan</p>
          <Link to="/job-listings" className="explore-btn">
            Explore Jobs
          </Link>
        </div>
      </section>

      <section className="about-us" id="aboutus">
        <div className="about-content">
          <h2>About Us</h2>
          <p>
            Welcome to the dynamic collaboration between DN Polytechnic and LP Staff Inc. - a synergistic alliance aimed
            at fostering excellence and propelling the careers of aspiring engineers. Established in 1975, DN
            Polytechnic, located in the vibrant city of Ahmedabad, India, has been a bastion of top-notch education,
            nurturing talents that have made significant contributions to the engineering landscape. Complementing this
            educational powerhouse is LP Staff Inc., a distinguished placement consultant based in Japan. With a proven
            track record of connecting exceptional talents with leading companies in the Land of the Rising Sun, LP
            Staff Inc. brings rich experience and industry insights to the partnership. DN Polytechnic and LP Staff Inc.
            unite to seamlessly integrate DN Polytechnic talents into top Japanese companies. This collaboration goes
            beyond placement, symbolizing our dedication to shaping global engineering excellence.
          </p>
        </div>
        <div className="vision-mission">
          <div className="vision-content">
            <FaBullseye className="icon" />
            <h2>Vision</h2>
            <p>
              Our vision is to bridge exceptional Indian engineering talent with top Japanese companies, catalyzing
              international success and fostering a global community of skilled professionals driving technological
              advancements and innovation.
            </p>
          </div>
          <div className="mission-content">
            <FaHandshake className="icon" />
            <h2>Mission</h2>
            <p>
              Our mission is to facilitate the transition of bright engineering minds into top-tier Japanese companies,
              thereby enriching their professional experiences and fostering international collaboration.
            </p>
          </div>
        </div>

        <div className="skilled-manpower" id="skilled">
          <h3>Skilled Manpower Requirement</h3>
          <div className="requirements">
            <div className="requirement">
              <FaBuilding className="icon" />
              <p>Civil Engineers</p>
            </div>
            <div className="requirement">
              <FaCar className="icon" />
              <p>Automobile Engineers</p>
            </div>
            <div className="requirement">
              <FaCog className="icon" />
              <p>Mechanical Engineers</p>
            </div>
            <div className="requirement">
              <FaBolt className="icon" />
              <p>Electrical Engineers</p>
            </div>
            <div className="requirement">
              <FaCode className="icon" />
              <p>Software Engineers</p>
            </div>
          </div>
        </div>
      </section>

      <section className="core-values" id="core">
        <h2>Core Values</h2>
        <div className="core-values-container">
          <div className="core-value-card">
            <FaBook className="icon" />
            <h3>Excellence</h3>
            <p>
              We are committed to upholding the highest standards of academic and professional excellence, fostering a
              culture that encourages continuous learning and innovation.
            </p>
          </div>
          <div className="core-value-card">
            <FaShieldAlt className="icon" />
            <h3>Integrity</h3>
            <p>
              Our actions are guided by integrity and transparency, ensuring that every interaction is conducted with
              honesty and ethical responsibility.
            </p>
          </div>
          <div className="core-value-card">
            <FaHandsHelping className="icon" />
            <h3>Collaboration</h3>
            <p>
              We believe in the power of collaboration, leveraging the strengths of both DN Polytechnic and LP Staff
              Inc. to create a holistic and enriching experience for individuals (students & working professionals).
            </p>
          </div>
          <div className="core-value-card">
            <FaGlobe className="icon" />
            <h3>Global Perspective</h3>
            <p>
              Embracing a global perspective, we seek to instill in individuals a deep understanding of diverse cultures
              and practices, preparing them for success in an interconnected world.
            </p>
          </div>
          <div className="core-value-card">
            <FaBriefcase className="icon" />
            <h3>Career Development</h3>
            <p>
              We are dedicated to the professional growth and success of individuals, providing them with the tools and
              opportunities to thrive in their chosen fields.
            </p>
          </div>
        </div>
      </section>

      <section className="services" id="services">
        <h2>Our Services</h2>
        <div className="services-container">
          <div className="service-item">
            <FaFileSignature className="icon" />
            <p>Offer Letter and MOU</p>
          </div>
          <div className="service-item">
            <FaUserTie className="icon" />
            <p>Work Permit</p>
          </div>
          <div className="service-item">
            <FaLanguage className="icon" />
            <p>Language Training (45 Days or 120 Hours)</p>
          </div>
          <div className="service-item">
            <FaPassport className="icon" />
            <p>Visa</p>
          </div>
          <div className="service-item">
            <FaBriefcaseMedical className="icon" />
            <p>Medical</p>
          </div>
        </div>
        <p className="terms-conditions">
          Terms & Conditions:
          <br />
          • Minimum work permit - 3 years.
          <br />
          • Processing fee will be non-refundable.
          <br />• Any dispute will be subject to Ahmedabad jurisdiction.
        </p>
      </section>

      <section className="job-details" id="jobdetails">
        <h2>Job Details</h2>
        <div className="job-info">
          <div className="job-item">
            <FaCalendarAlt className="icon" />
            <p>Working Days: 5 Days/Week</p>
          </div>
          <div className="job-item">
            <FaClock className="icon" />
            <p>Working Hours: 40 Hours / Week</p>
          </div>
          <div className="job-item">
            <FaMoneyBillAlt className="icon" />
            <p>Monthly Salary: 2,30,000+ Japanese Yen</p>
          </div>
          <div className="job-item">
            <FaMoneyCheckAlt className="icon" />
            <p>Overtime Earnings: A maximum amount of 50,000 Japanese Yen can be earned as overtime in a month.</p>
          </div>
          <div className="job-item">
            <FaMoneyBillWave className="icon" />
            <p>Processing Fees: INR 25,000/- (Non-Refundable)</p>
          </div>
          <div className="job-item">
            <FaStopwatch className="icon" />
            <p>Processing Duration: 60 to 90 Days</p>
          </div>
        </div>
      </section>

      <section className="job-registration" id="jobregister">
        <h2>Register for Job Opportunities</h2>
        <p>Looking for a job opportunity in Japan? Register now to explore exciting career prospects!</p>

        <a
          href="https://forms.gle/X2NkkmHaF2NHLCs7A"
          target="_blank"
          rel="noopener noreferrer"
          className="job-registration-btn"
        >
          Register for Jobs
        </a>

        <div className="brochures">
          <h3>Brochures</h3>
          <ul>
            <li>
              <a href={firstbroucher} target="_blank" rel="noopener noreferrer">
                First Brochure
              </a>
            </li>
            <li>
              <a href={secondbroucher} target="_blank" rel="noopener noreferrer">
                Second Brochure
              </a>
            </li>
            <li>
              <a href={thirdbroucher} target="_blank" rel="noopener noreferrer">
                Third Brochure
              </a>
            </li>
          </ul>
        </div>
      </section>

      <footer>
        <p>&copy; 2024 Skilltojob. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default Home;
